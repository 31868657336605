@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  scroll-behavior: smooth;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 1px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 4px;
  border: 2px solid var(--primary);
}

:root {
  --primary: rgb(255, 255, 255);
  --secondary: rgb(100, 237, 173);
}

.swiper-pagination-bullet {
  height: 6px !important;
  width: 8px !important;
  border-radius: 20px !important;
  background-color: rgb(100, 237, 173) !important;
}

.swiper-pagination-bullet-active {
  background-color: rgb(100, 237, 173) !important;
  width: 20px !important;
}
